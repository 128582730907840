<template>
  <div class="common-detail-view W100">
    <ak-container type="white" class="header-info-main" card :bodyStyle="{ padding: 0 }" :loading="loading">
      <!-- 个人信息-->
      <DetailTop :businessResp="businessResp" :personalResp="personalResp"></DetailTop>
      <div class="authInfo">
        <!-- 企业认证 -->
        <template v-if="detailData && detailData?.business?.businessTag === 2">
          <div class="bussinessReal">
            <a-card>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                企业真实性认证信息
              </div>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="4">
                  <span>企业名称 </span>
                </a-col>
                <a-col :span="20">{{ realBusiCertification.enterpriseName }}</a-col>
              </a-row>
              <a-row>营业执照</a-row>
              <a-row>
                <viewer :images="[`${$const.OssImagePrefix}/${realBusiCertification.busiLicensePic}`]">
                  <img class="detail-img" :src="`${$const.OssImagePrefix}/${realBusiCertification.busiLicensePic}`" />
                </viewer>
              </a-row>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="4">
                  <span>社会统一信用代码</span>
                </a-col>
                <a-col :span="20">
                  {{ realBusiCertification.busiLicenseCode }}</a-col>
              </a-row>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="4">
                  <span>营业执照有效期</span>
                </a-col>
                <a-col :span="20">{{ realBusiCertification.busiLicenseAvailable }}</a-col>
              </a-row>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="4">
                  <span>法人姓名</span>
                </a-col>
                <a-col :span="20">{{realBusiCertification.legalmanLicenceName }}</a-col>
              </a-row>
              <a-row>法人身份证</a-row>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6">
                  <viewer :images="[`${$const.OssImagePrefix}/${realBusiCertification.legalmanLicenceHandpic}`]">
                    <img class="detail-img"
                      :src="`${$const.OssImagePrefix}/${realBusiCertification.legalmanLicenceHandpic}`" />
                  </viewer>
                </a-col>
                <a-col :span="6">
                  <viewer :images="[`${$const.OssImagePrefix}/${realBusiCertification.legalmanLicencePic}`]">
                    <img class="detail-img"
                      :src="`${$const.OssImagePrefix}/${realBusiCertification.legalmanLicencePic}`" />
                  </viewer>
                </a-col>
                <a-col :span="6">
                  <viewer :images="[`${$const.OssImagePrefix}/${realBusiCertification.legalmanLicenceBackpic}`]">
                    <img class="detail-img"
                      :src="`${$const.OssImagePrefix}/${realBusiCertification.legalmanLicenceBackpic}`" />
                  </viewer>
                </a-col>
              </a-row>
            </a-card>
          </div>
          <div class="infoCertification" v-if="authentication && authentication.authentication">
            <a-card>
              <div class="F20 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                企业认证
              </div>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                简介认证
              </div>
              <p>
                {{ authentication.introduction }}
              </p>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                品牌认证
              </div>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                经营地址认证
              </div>
              <p>{{authentication.addressCertification.shopAdress }}</p>
              <p v-if="authentication.addressCertification">
                营业中 &nbsp;&nbsp;{{authentication.addressCertification.workDaysBegin }}
                - {{ authentication.addressCertification.workDaysEnd }}
                {{ authentication.addressCertification.workTimeBegin }} -
                {{ authentication.addressCertification.workTimeEnd }}
              </p>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                办公环境认证
              </div>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6" v-for="(pic,idx) in authentication.officeEnvironmentCertification" :key="idx">
                  <viewer :images="[`${$const.OssImagePrefix}/${pic}`]">
                    <img class="detail-img" :src="`${$const.OssImagePrefix}/${pic}`" />
                  </viewer>
                </a-col>
              </a-row>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                身份认证
              </div>
              <p>
                {{ authentication?.certification?.certificationMsg }}
              </p>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6" v-for="(pic,idx) in authentication.certificationImg" :key="idx">
                  <viewer :images="[`${$const.OssImagePrefix}/${pic}`]">
                    <img class="detail-img" :src="`${$const.OssImagePrefix}/${pic}`" />
                  </viewer>
                </a-col>
              </a-row>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                经历认证
              </div>
              <div v-for="(exp,idx) in authentication?.experienceCertifications" :key="idx">
                <p>{{ exp && exp.timePeriod }} {{ exp && exp.title }}</p>
                <a-row :gutter="gutter" class="PB16">
                  <a-col :span="6" v-for="(expImg,idx) in exp.experiencePics" :key="idx">
                    <viewer :images="[`${$const.OssImagePrefix}/${expImg}`]">
                      <img class="detail-img" :src="`${$const.OssImagePrefix}/${expImg}`" />
                    </viewer>
                  </a-col>
                </a-row>
              </div>

              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                获奖认证
              </div>
              <div v-for="(exp,idx) in authentication?.awardCertifications" :key="idx">
                <p>{{ exp && exp.timePeriod }} {{ exp && exp.title }}</p>
                <a-row :gutter="gutter" class="PB16">
                  <a-col :span="6" v-for="(expImg,idx) in exp.experiencePics" :key="idx">
                    <viewer :images="[`${$const.OssImagePrefix}/${expImg}`]">
                      <img class="detail-img" :src="`${$const.OssImagePrefix}/${expImg}`" />
                    </viewer>
                  </a-col>
                </a-row>
              </div>

              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                展览认证
              </div>
              <div v-for="(exp,idx) in authentication?.exhibitionCertifications" :key="idx">
                <p>{{ exp && exp.timePeriod }} {{ exp && exp.title }}</p>
                <a-row :gutter="gutter" class="PB16">
                  <a-col :span="6" v-for="(expImg,idx) in exp.experiencePics" :key="idx">
                    <viewer :images="[`${$const.OssImagePrefix}/${expImg}`]">
                      <img class="detail-img" :src="`${$const.OssImagePrefix}/${expImg}`" />
                    </viewer>
                  </a-col>
                </a-row>
              </div>
            </a-card>
          </div>
        </template>
        <!-- 个人认证 -->
        <template v-else>
          <div class="realBusiCertification" v-show="realNameCertification&&realNameCertification.name !== ''">
            <a-card>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                实名认证信息
              </div>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="4">
                  <span>真实姓名 </span>
                </a-col>
                <a-col :span="20">{{ realNameCertification&&realNameCertification.name }}</a-col>
              </a-row>
              <a-row :gutter="gutter" class="PB16"
                v-if="realNameCertification&&realNameCertification?.backIdentityCard !== ''">
                <a-col :span="12">
                  <viewer
                    :images="[`${$const.OssImagePrefix}/${realNameCertification&&realNameCertification.handIdentityCard}`]">
                    <img class="detail-img"
                      :src="`${$const.OssImagePrefix}/${realNameCertification&&realNameCertification.handIdentityCard}`" />
                  </viewer>
                </a-col>
                <a-col :span="12">
                  <viewer
                    :images="[`${$const.OssImagePrefix}/${realNameCertification&&realNameCertification.frontIdentityCard}`]">
                    <img class="detail-img"
                      :src="`${$const.OssImagePrefix}/${realNameCertification&&realNameCertification.frontIdentityCard}`" />
                  </viewer>
                </a-col>
                <a-col :span="12">
                  <viewer :images="[`${$const.OssImagePrefix}/${realNameCertification.backIdentityCard}`]">
                    <img class="detail-img"
                      :src="`${$const.OssImagePrefix}/${realNameCertification.backIdentityCard}`" />
                  </viewer>
                </a-col>
              </a-row>
            </a-card>
          </div>
          <div class="infoCertification">
            <a-card>
              <div class="F20 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                个人认证
              </div>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                简介认证
              </div>
              <p>{{ authentication.introduction }}</p>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                品牌认证
              </div>
              <div class="brandCertification" v-if="authentication &&authentication.brandCertification">
                <a-row :gutter="gutter" class="PB16">
                  <a-col :span="6">商标注册证</a-col>
                  <a-col :span="6" style="margin-left: 30px">
                    商标名称：{{ authentication?.brandCertification?.brandName }}</a-col>
                </a-row>
                <a-row :gutter="gutter" class="PB16">
                  <a-col :span="6">
                    <viewer :images="[`${$const.OssImagePrefix}/${authentication.brandCertification.brandRegistPic}`]">
                      <img class="detail-img"
                        :src="`${$const.OssImagePrefix}/${authentication.brandCertification.brandRegistPic}`" />
                    </viewer>
                  </a-col>
                  <a-col :span="6" style="margin-left: 30px">
                    <viewer :images="[`${$const.OssImagePrefix}/${authentication.brandCertification.brandLogoPic}`]">
                      <img class="detail-img"
                        :src="`${$const.OssImagePrefix}/${authentication.brandCertification.brandLogoPic}`" />
                    </viewer>
                  </a-col>
                </a-row>
                <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                  商标授权书
                </div>
                <a-row :span="6">
                  <viewer :images="[`${$const.OssImagePrefix}/${authentication.brandCertification.brandAuthorizePic}`]">
                    <img class="detail-img"
                      :src="`${$const.OssImagePrefix}/${authentication.brandCertification.brandAuthorizePic}`" />
                  </viewer>
                </a-row>
              </div>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                经营地址认证
              </div>
              <p>{{ authentication?.addressCertification?.shopAdress }}
              </p>
              <p v-if="!authentication.addressCertification">
                营业中 &nbsp;&nbsp;{{authentication?.addressCertification?.workDaysBegin}}
                - {{ authentication?.addressCertification?.workDaysEnd }}
                {{ authentication?.addressCertification?.workTimeBegin }} -
                {{ authentication?.addressCertification?.workTimeEnd }}
              </p>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                办公环境认证
              </div>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6" v-for="(pic,idx) in authentication.officeEnvironmentCertification" :key="idx">
                  <viewer :images="[`${$const.OssImagePrefix}/${pic}`]">
                    <img class="detail-img" :src="`${$const.OssImagePrefix}/${pic}`" />
                  </viewer>
                </a-col>
              </a-row>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                身份认证
              </div>
              <p>{{authentication?.certification?.certificationMsg}}</p>
              <a-row :gutter="gutter" class="PB16">
                <a-col :span="6" v-for="(pic,idx) in authentication.certificationImg" :key="idx">
                  <viewer :images="[`${$const.OssImagePrefix}/${pic}`]">
                    <img class="detail-img" :src="`${$const.OssImagePrefix}/${pic}`" />
                  </viewer>
                </a-col>
              </a-row>
              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                经历认证
              </div>
              <div v-for="(exp,idx) in authentication.experienceCertifications" :key="exp">
                <p>{{ exp && exp.timePeriod }} {{ exp && exp.title }}</p>
                <a-row :gutter="gutter" class="PB16">
                  <a-col :span="6" v-for="(expImg,idx) in exp.experiencePics" :key="idx">
                    <viewer :images="[`${$const.OssImagePrefix}/${expImg}`]">
                      <img class="detail-img" :src="`${$const.OssImagePrefix}/${expImg}`" />
                    </viewer>
                  </a-col>
                </a-row>
              </div>

              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                获奖认证
              </div>
              <div v-for="exp in authentication.awardCertifications" :key="exp">
                <p>{{ exp && exp.timePeriod }} {{ exp && exp.title }}</p>
                <a-row :gutter="gutter" class="PB16">
                  <a-col :span="6" v-for="(expImg,idx) in exp.experiencePics" :key="idx">
                    <viewer :images="[`${$const.OssImagePrefix}/${expImg}`]">
                      <img class="detail-img" :src="`${$const.OssImagePrefix}/${expImg}`" />
                    </viewer>
                  </a-col>
                </a-row>
              </div>

              <div class="F16 F-BOLD" style="font-weight: bold; margin-bottom: 10px">
                展览认证
              </div>
              <div v-for="(exp,idx) in authentication.exhibitionCertifications" :key="idx">
                <p>{{ exp && exp.timePeriod }} {{ exp && exp.title }}</p>
                <a-row :gutter="gutter" class="PB16">
                  <a-col :span="6" v-for="(expImg,idx) in exp.experiencePics" :key="idx">
                    <viewer :images="[`${$const.OssImagePrefix}/${expImg}`]">
                      <img class="detail-img" :src="`${$const.OssImagePrefix}/${expImg}`" />
                    </viewer>
                  </a-col>
                </a-row>
              </div>
            </a-card>
          </div>
        </template>
      </div>
    </ak-container>
  </div>
</template>

<script>
  import * as Business from "@/api/user/business";
  import DetailTop from '../../components/detail-top.vue'

  export default {
    components: {
      DetailTop
    },
    data() {
      return {
        gutter: 24,
        loading: false,
        detailData: {},
        realNameCertification: {},
        realBusiCertification: {},
        authentication: {},
        businessResp: {},
        personalResp: {},
      };
    },
    created() {
      this.userId = this.$route.query.id;
      this.queryDetail();
    },
    methods: {
      queryDetail() {
        this.loading = true;
        const param = {
          userId: this.userId,
        };
        Business.queryBusinessById(param)
          .then((res) => {
            const data = res.data || {};
            this.detailData = data || {};
            this.realNameCertification = data.realNameCertification || {};
            this.realBusiCertification = data.realBusiCertification || {};
            this.authentication = data.authentication || {};
            this.businessResp = res.data.business || {}
            this.personalResp = res.data.personalResp || {}
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
<style>
  .create-bk-card {
    margin-top: 20px;
  }

  .bk_creater {
    width: 100%;
    height: 400px;
    background: rgb(237, 240, 245) url(../../../assets/bk_creater.png) 0 0 no-repeat;
  }

  .bussiness,
  .bussinessReal,
  .infoCertification {
    margin-top: 20px;
  }

  .authInfo {
    padding: 0 20px 20px 20px;
  }
</style>
<style lang="less" scoped>
  .common-detail-view {
    .C666 {
      display: inline-block;
      width: 90px;
      text-align: right;
    }
  }

  .left-detail-card {
    width: 100%;
  }

  /deep/ .bindUser .ant-card-body {
    padding: 24px 0 6px 24px;
  }

  .ant-col {
    margin-bottom: 8px;
  }

  .ant-col:last-child {
    margin-bottom: 0;
  }

  // .ant-row{
  //   margin-bottom: 8px;
  // }
  .ant-row:last-child {
    margin-bottom: 0;
  }
</style>